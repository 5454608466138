// .ant-input-group,
.ant-input-affix-wrapper {
  border-radius: 12px;
  font-size: 10px;
  line-height: 1.4em;
  padding: 0;
  height: 28px;

  input {
    font-size: 14px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .ant-input-suffix {
    font-size: 16px;
    color: var(--secondary-45);
    padding: 5px 9px;
    margin-left: 0;
    border-left: 1px solid var(--input-placeholder-color);
  }

  .ant-input-prefix {
    font-size: 16px;
    color: var(--secondary-45);
    padding: 5px 9px;
    margin-right: 0;
    border-right: 1px solid var(--input-placeholder-color);
  }

  &:focus,
  &-focused {
    box-shadow: none;
  }
}
