:host {
  button {
    text-wrap: wrap;
  }

  i {
    display: inline-block;
    font-size: 1em;
  }
}

.ant-btn {
  &:hover,
  &:focus,
  &:active {
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
    color: #fff;
  }

  &-text {
    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: var(--primary-dark-hover);
    }
  }

  &-link {
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border-color: transparent;
      color: var(--primary-dark);
    }
  }

  &-primary {
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: var(--primary-dark-hover);
      border-color: var(--primary-dark-hover);
    }
  }

  &-dangerous {
    border-color: var(--primary-red-6);
    color: var(--primary-red-6);

    &:hover,
    &:focus,
    &:active {
      color: var(--primary-red-6-hover);
      background-color: #fff;
      border-color: var(--primary-red-6-hover);
    }
  }
}
