section.aiomed-sider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 21px;

  ul.aiomed-sider-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
      width: 64px;
      height: 56px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      margin-bottom: 21px;
      transition: ease-in-out 0.1s;

      &.active {
        background: #535d77 0 0 no-repeat padding-box;
        border-radius: 18px;
        transition: ease-in-out 0.1s;

        svg {
          fill: white;
          color: white;
        }

        .default-insights {
          display: none;
        }

        .active-insights {
          display: inline-block;
        }
      }

      .active-insights {
        display: none;
      }

      svg {
        font-size: 45px;
        fill: #a8aebc;
        color: #a8aebc;
      }

      .ant-badge-count,
      .ant-badge-dot,
      .ant-badge .ant-scroll-number-custom-component {
        //right: 5px;
        top: 8px;
      }

      .ant-badge-count {
        width: 16px;
        height: 16px;
        padding: 0;
        font-size: 8px;
        box-shadow: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
      }

      &:hover {
        background: #535d77 0 0 no-repeat padding-box;
        border-radius: 18px;
        transition: ease-in-out 0.1s;

        svg {
          fill: white;
          color: white;
        }
      }
    }

    li.shift-planer {
      position: relative;

      &:hover {
        .shift-planer-text {
          color: #fff;
        }
      }

      svg {
        font-size: 29px;
        margin-bottom: 21px;
      }

      div.shift-planer-text {
        font: normal normal normal 8px/24px Lato;
        color: #a8aebc;
        position: absolute;
        bottom: 0;
      }

      &.active {
        div.shift-planer-text {
          color: #fff;
        }
      }

      &:hover {
        div.shift-planer-text {
          color: #fff;
        }
      }
    }

    li.insights {
      &:hover {
        .default-insights {
          display: none;
        }

        .active-insights {
          display: inline-block;
        }
      }
    }
  }

  div.aiomed-sider-menu-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img,
    span {
      width: 25px;
      height: 25px;
      border: 1px solid #ffffff;
      border-radius: 50%;
      display: flex;
      font-size: 8px;
      justify-content: center;
      align-items: center;
      background: #ccc;
    }

    div {
      font: normal normal normal 12px/24px Lato;
      color: #ffffff;
    }
  }
}

// .menu {
//   position: fixed;
//   width: 260px;
//   transition: ease-in-out .1s;
// }

// .menu-collapsed {
//   width: 96px;
//   transition: ease-in-out .1s;
// }
