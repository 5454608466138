section.entities-table {
  padding: 19px 22px 10px 0;
  position: relative;
  min-height: calc(100vh - 240px);
  text-align: right;
  max-width: 100%;

  nz-table {
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #a0a0a0;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #a0a0a0;
    }

    padding-top: 10px;

    .nz-table-hide-scrollbar {
      overflow-y: auto !important;
    }

    thead {
      tr {
        th {
          // padding: 8.5px 16px;
          text-align: left;
          //text-transform: uppercase;
          color: #758198;
          font-weight: 700;

          svg {
            color: #758198;
          }

          .ant-table-column-sorters,
          .ant-table-filter-column {
            width: fit-content;
          }
        }

        th.item-name {
          .ant-checkbox-wrapper {
            // margin-right: 34px;
          }

          .ant-checkbox-inner {
            border-radius: initial;
          }

          .ant-checkbox-checked .ant-checkbox-inner {
            // background-color: #263558;
            // border-color: #263558;
          }
        }

        th.checkbox {
          padding-left: 23px;
        }

        th.review-abnormal {
          padding-left: 58px;
        }
      }
    }

    tbody {
      tr {
        // padding-top: 5px;
        // padding-bottom: 5px;
        // cursor: pointer;
        // border-bottom: 1px solid #f4f4f8;
        // position: relative;

        // td.checkbox {
        //   display: flex;
        //   align-items: center;
        //   padding-left: 10px;

        label,
        div {
          z-index: 1;
        }

        label {
          padding-left: 13px;
        }
      }

      td.absolute {
        left: 0;
        position: absolute;
        width: 100%;
        opacity: 1;

        div {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
      }

      td.report-emr {
        div {
          justify-content: center;
          align-items: center;
          margin-left: -10px;
        }
      }

      td {
        // padding: 8px 0;

        // .ant-checkbox-inner {
        //   border-radius: initial;
        // }

        // .ant-checkbox-checked .ant-checkbox-inner {
        //   background-color: #263558;
        //   border-color: #263558;
        // }

        // &:first-child {
        //   div {
        //     padding-left: 17px;
        //     border-top-left-radius: 8px;
        //     border-bottom-left-radius: 8px;
        //     justify-content: left;
        //   }
        // }

        // &:last-child {
        //   div {
        //     border-top-right-radius: 8px;
        //     border-bottom-right-radius: 8px;
        //   }
        // }

        div.content {
          // color: #252a36;
          // font-weight: 400;
          // font-size: 14px;
          // height: 43px;
          // display: flex;
          // //justify-content: center;
          // align-items: center;
          // text-transform: capitalize;
          // padding-left: 16px;

          i {
            font-size: 15px;
          }

          i.empty-line {
            font-size: 42px;
          }

          i.report-edit {
            font-size: 25px;
          }

          i.manual {
            color: #252a36;
            font-size: 14px;
            margin-right: 3px;
          }

          i.report-emr {
            font-size: 25px;
            margin-bottom: 2px;
            color: #263558;

            &[data-vital-emr-status='indicator-partial-export-emr'] {
              color: #fff000;
            }
          }

          i.critical,
          i.warning,
          i.warning-tablet {
            margin-left: 1px;
            font-size: 15px;
            opacity: 0;
            visibility: hidden;
            position: absolute;
          }

          i.warning-tablet {
            font-size: 22px;
          }

          span.item-value {
            padding: 0 2px;
          }

          span.insight-ended-time {
            font-size: 11px;
            color: #758198;
          }

          span.temp {
            // font-size: 12px;
            // margin-top: 2px;
            // margin-left: 2px;
          }

          span.item-small {
            margin-top: 3px;
            margin-left: 2px;
            font-size: 9px;
          }

          span.item-degree {
            margin-left: 2px;
            margin-bottom: 6px;
            font-size: 9px;
          }

          span.last-emr-complete-time {
            color: #fff;
            font-size: 11px;
            font-weight: 700;
            width: 56px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            background: #a2afc3;
            visibility: hidden;
            opacity: 0;
            margin-top: 26px;
          }

          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
          }

          ul.custom-symptoms {
            li {
              height: 21px;
              border-radius: 10px;
              display: flex;
              align-items: center;
              padding: 0 10px;
              font-size: 13px;
              margin: 0 8px 8px 0;
            }

            li.symptom {
              border: 1px solid #778196;
              background: #ffffff 0 0 no-repeat padding-box;
            }

            li.condition {
              border: 1px solid #778196;
              background: #758198 0 0 no-repeat padding-box;
              color: #ffffff;
            }
          }

          span.monitored {
            width: 10px;
            height: 10px;
            background: #5be18e 0 0 no-repeat padding-box;
            border-radius: 50%;
          }

          div.report-edit,
          button.report-edit {
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            transition: ease-in-out 0.3ms;
            position: absolute;
            cursor: pointer;

            span.loading {
              position: absolute;
              font-size: 18px;
              top: 20px;
            }

            //&:hover {
            //  background: #F1F2F589;
            //  transition: ease-in-out .3ms;
            //}
          }

          div.ended-container {
            display: flex;
            flex-direction: column;
          }

          button.report-edit {
            background: transparent;
            border: none;

            &[disabled] {
              i {
                cursor: not-allowed !important;
                color: rgb(229, 229, 229) !important;
                // TODO: In theory we could share these constants between SCSS and TS using ":export" directive but it is overkill
                &[data-vital-emr-status='indicator-partial-export-emr'] {
                  color: #fff000 !important;
                  opacity: 0.5;
                }

                &[data-vital-emr-status='indicator-complete'] {
                  color: #0fc180 !important;
                  opacity: 0.5;
                }

                &[data-vital-emr-status='indicator-need-export'] {
                  color: #fff000 !important;
                  opacity: 0.5;
                }
              }
            }
          }

          div.device-actions {
            width: 36px;
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            border-radius: 5px;
            border: none !important;
            margin-left: 4px;
            transition: ease-in-out 0.3s;

            &:hover {
              transition: ease-in-out 0.3s;
              background: #f6f6f9 0 0 no-repeat padding-box;
            }

            i {
              z-index: 1001;
              font-size: 17px;
            }
          }

          span.gray {
            color: #a2afc3;
          }

          i {
            &[data-vital-emr-status='indicator-complete'] {
              color: #0fc180;
            }

            &[data-vital-emr-status='indicator-need-export'] {
              color: #fff000;
            }
          }
        }

        div.missing-vital,
        div.missing-vital-abnormal {
          width: 100px;
          position: relative;
          padding-left: 6px;
          padding-right: 6px;
          line-height: 15px;
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 37px;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-image: repeating-linear-gradient(
              125deg,
              #fff5d2 0,
              #fff5d2 5px,
              rgba(0, 0, 0, 0) 5px,
              rgba(0, 0, 0, 0) 10px
            );
          }
        }

        div.missing-vital-abnormal {
          width: 67px;
          justify-content: center;
        }

        div.vital-complete {
          button.report-edit {
            margin-top: -18px;

            span.loading {
              top: 14px !important;
            }
          }

          span.last-emr-complete-time {
            visibility: visible;
            opacity: 1;
            z-index: 1;
            //padding-top: 10px;
          }
        }

        div.need-calibrate {
          color: #c6c6c9;
        }

        div.monitored {
          padding-left: 45px;

          .sensor-animation {
            width: 23px;
            height: 23px;
            margin-left: -6px;
          }
        }

        div.white {
          background: #fff;
        }

        div.bold {
          font-weight: bold;
        }

        div.old-status {
          color: #b3b5b5;

          i {
            color: #b3b5b5;
            animation: none;
          }
        }

        div.input-box,
        div.bp-input-box {
          &:focus-within {
            border: 2px solid #263558;
          }

          input {
            width: 78%;
            border: none;
            border-radius: 5px;
            outline: none;
            height: 28px;
            font: normal normal normal 14px/24px Lato;
            color: #262626;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }

        div.input-box.red-value,
        div.bp-input-box.red-value {
          &:focus-within {
            border: 2px solid #e7424a;
          }
        }

        div.bp-input-box {
          position: relative;

          &:focus-within {
            span.bp-slash {
              position: absolute;
              left: 44%;
            }
          }

          input {
            width: 40%;
            padding-right: 7px;
          }

          span.bp-slash {
            position: absolute;
            left: 33%;
          }

          span.manual {
            position: absolute;
            left: 44% !important;
            transform: translateX(12px);
          }
        }

        div.critical-value,
        div.critical-value-d,
        div.red-value,
        div.red-value-d {
          color: #252a36;
          font-weight: 700;

          //input {
          //  color: red;
          //}
          //
          //i.manual {
          //  color: #E7424A;
          //}

          i.warning {
            opacity: 1;
            visibility: visible;
            position: relative;
          }
        }

        div.critical-value,
        div.critical-value-d {
          div.wrapper {
            color: #e7424a;
            border-radius: 10px;
            background: #fce4e5 !important;
            height: 26px;
            display: flex;
            align-items: center;
            padding: 0 5px;

            .tooltip-insight {
              display: flex;
              justify-content: space-between;
            }
          }

          i.warning {
            opacity: 0;
            visibility: hidden;
            position: absolute;
          }

          i.critical {
            opacity: 1;
            visibility: visible;
            position: relative;
          }
        }

        div.table-name {
          // justify-content: flex-start;
          // align-items: center;
          // padding-left: 17px;

          img {
            border-radius: 50%;
          }

          span {
            padding-left: 14px;
          }
        }

        div.batteryLevel {
          i {
            font-size: 22px;
            margin-right: 3px;
          }
        }
      }

      &[severity='critical'] {
        td {
          div {
            // background: var(--table-red-color);
          }
        }
      }

      &[severity='major'] {
        td {
          div {
            background: var(--table-yellow-color);
          }
        }
      }
    }

    // tr.has-hover {
    //   &:hover {
    //     td {
    //       transition: ease-in-out .3s;

    //       div {
    //         background: #F5F5F5 !important;
    //         transition: ease-in-out .3s;
    //       }

    //       div.missing-vital, div.missing-vital-abnormal {
    //         background: #FFF !important;
    //       }
    //     }
    //   }
    // }
  }
}

.ant-table-content {
  height: 555px;
}

.ant-table-tbody {
  position: relative !important;
  margin-top: 30px !important;
}

.ant-table-container {
  .scroll-content {
    min-height: 400px !important;
  }
}

.entities-table {
  &__title {
    display: flex;
    justify-content: flex-start;
    font: normal normal bold 16px/24px Lato;
    color: #252a36;
    text-transform: uppercase;
  }

  &__search-pagination-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //justify-content: space-between;
    margin-top: 11px;
    margin-bottom: 6px;

    .ant-input-affix-wrapper {
      // border: 1px solid #DBDCDD;
      // border-radius: 11px;
      // padding: 0 11px;
      // height: 28px;
      width: 336px;
    }

    ul.device-filters {
      list-style: none;
      margin: 0 0 0 18px;
      padding: 0;
      display: flex;

      li {
        height: 32px;
        border-radius: 16px;
        background: #dddede;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 18px;
        margin-right: 8px;
        font: normal normal normal 14px/20px Lato;
        letter-spacing: 0.25px;
        color: #000000de;
        cursor: pointer;
        transition: ease-in-out 0.3s;

        &.active {
          background: #263558;
          color: #ffffffde;
        }
      }

      &.report-filter {
        li {
          width: 107px;
          padding: 0;
        }
      }
    }

    //ul.report-filter {
    //  width: 92px !important;
    //}

    button.assign-device {
      position: absolute;
      right: 57px;
      border: 0.5px solid #263558;
      border-radius: 4px;
      background: transparent;
      padding: 0;
      outline: none;
      font: normal normal normal 12px/24px Lato;
      color: #263558;
      transition: ease-in-out 0.3s;
      width: 132px;
      height: 32px;
      cursor: pointer;

      &:hover {
        transition: ease-in-out 0.3s;
        background: #263558 0 0 no-repeat padding-box;
        border-radius: 4px;
        color: #ffffff;
      }
    }
  }

  &__tab-container {
    margin: 14px 0 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    position: relative;
    width: 98.6%;
    border-bottom: 1px solid #dddede;

    li {
      padding: 0 23px 12px 23px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      transition: ease-in-out 0.1s;
      font: normal normal medium 14px/24px Lato;
      color: #758198;
      position: relative;

      &.active {
        border-bottom: 2px solid #263558;
        transition: ease-in-out 0.1s;
        font: normal normal bold 14px/24px Lato;
        color: #263558;
      }

      span {
        position: absolute;
        top: -5px;
        right: -4px;
        width: 22px;
        height: 22px;
        background: #e8effa 0 0 no-repeat padding-box;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #758198;
      }
    }
  }

  &__filters {
    margin: 0 0 0 17px;
    padding: 0;
    display: flex;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 32px;
      border-radius: 20px;
      outline: none;
      border: transparent;
      color: #000;
      font-size: 16px;
      font-weight: 400;
      background: #dddddd;

      &.active {
        background: #758198;
        color: #fff;
        font-weight: 700;
      }

      &:nth-of-type(1) {
        width: 97px;
        margin-right: 16px;
      }

      &:nth-of-type(2) {
        width: 81px;
      }
    }
  }

  //&__filter-buttons {
  //  display: flex;
  //  margin-left: 16.5px;
  //  color: #000;
  //  font-size: 16px;
  //  font-weight: 400;
  //
  //
  //  button {
  //    height: 32px;
  //    border-radius: 20px;
  //    outline: none;
  //    border: transparent;
  //    cursor: pointer;
  //
  //    &.active {
  //      background: #758198;
  //      color: #FFF;
  //      font-weight: 700;
  //    }
  //
  //    &:nth-of-type(1) {
  //      width: 81px;
  //      margin-right: 16px;
  //    }
  //
  //    &:nth-of-type(2) {
  //      width: 205px;
  //    }
  //  }
  //}

  button.import-pcc {
    position: absolute;
    right: 57px;
    border: 0.5px solid #263558;
    height: 32px;
    top: -39px;
    border-radius: 4px;
    font: normal normal normal 12px/24px Lato;
    color: #263558;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    background: #fff;

    &:hover {
      transition: ease-in-out 0.3s;
      background: #263558 0 0 no-repeat padding-box;
      color: #ffffff;
    }
  }
}
