.ant-modal-title {
  color: var(--primary-dark);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .subtitle {
    color: var(--neutral-6);
    font-size: 12px;
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  .ant-modal {
    margin: 0;
  }
}
