.ant-notification-notice {
  &-icon {
    font-size: 14px;
    line-height: 16px;
    top: 50%;
    transform: translateY(-50%);

    &.success {
      color: var(--success);
    }

    &.error {
      color: var(--danger);
    }

    &.warning {
      color: var(--warning);
    }
  }

  &-close {
    top: 10% !important;
    padding-left: 10px;
    border-left: 1px solid #0000000f;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-with-icon {
    .ant-notification-notice {
      &-message,
      &-description {
        margin-bottom: 0;
        margin-left: 30px;
        font-size: 14px;
        line-height: 22px;
        color: var(--primary-dark);
      }

      &-description {
        font-size: 12px;
        line-height: 18px;
        width: 80%;
      }
    }
  }

  &.success {
    background-color: var(--polar-green-1);
    border: 1px solid var(--polar-green-3);
  }

  &.warning {
    background-color: var(--calendula-gold-1);
    border: 1px solid var(--calendula-gold-3);
  }

  &.error {
    background-color: var(--dust-red-1);
    border: 1px solid var(--dust-red-2);
  }
}
