@use 'theme/main';
@use 'theme/table';
@use 'theme/loading';
@use 'theme/menu';
@use 'theme/overdue-task-list-actions-dropdown';

html,
body {
  overscroll-behavior: none;
}

::ng-deep {
  .tablet-center {
    max-height: 800px;
  }
}
