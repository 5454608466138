.ant-select {
  color: var(--primary-dark);

  .ant-select {
    &-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      ) {
      box-shadow: none;
    }
  }

  &-multiple {
    .ant-select-selector[ng-reflect-show-search='false'] {
      nz-select-search {
        display: none;
      }
    }
  }

  &-single {
    &:not(.ant-select-customize-input) .ant-select-selector {
      height: 57px;
      padding: 0 15px;
      background: transparent;
      border-color: var(--neutral-5);

      .ant-select-selection-search-input {
        height: 55px;
        font-size: 24px;

        &::after {
          line-height: 55px;
        }
      }
    }

    .ant-select-selector {
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: 57px;
      }

      .ant-select-selection-placeholder {
        font-size: 24px;
      }
    }

    &.big {
      &:not(.ant-select-customize-input) .ant-select-selector {
        height: 66px;

        .ant-select-selection-search-input {
          height: 66px;

          &::after {
            line-height: 64px;
          }
        }
      }

      .ant-select-selector {
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 66px;
        }
      }
    }

    &.small {
      &:not(.ant-select-customize-input) .ant-select-selector {
        height: 32px;

        .ant-select-selection-search-input {
          height: 32px;

          &::after {
            line-height: 30px;
          }
        }
      }

      .ant-select-selector {
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 32px;
          font-size: 14px;
        }
      }
    }
  }

  &-item {
    color: var(--primary-dark);
    padding-inline: 15px;

    &-option-disabled {
      color: var(--disabled-text-color);
    }

    &:has(span.big) {
      &:not(:last-child) {
        border-bottom: 1px solid var(--select-bottom-border-color);
      }
    }

    span.big {
      color: var(--primary-dark);
      display: flex;
      align-items: center;
      height: 60px;
      font-size: 22px;
      line-height: 26.4px;
    }
  }

  &-dropdown {
    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--scrollbar-color);
      border-radius: 99px;
    }

    &:has(span.big) {
      border-radius: 10px;
    }
  }

  &-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    ) {
    .ant-select-selector {
      box-shadow: none;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--primary-dark);
  background-color: var(--neutral-2);

  .ant-select-item-option-state {
    color: #fff;
  }

  .anticon {
    color: var(--primary-dark);
  }
}

.lib-select {
  position: relative;

  &.ant-select-single {
    .ant-select-selector {
      .ant-select-selection {
        &-item,
        &-placeholder {
          position: relative;
          top: 8px;
        }

        &-search {
          top: 8px;
        }
      }
    }

    &.big {
      .ant-select-selector {
        .ant-select-selection {
          &-item,
          &-placeholder {
            font-size: 24px;
          }
        }
      }

      .ant-select-arrow {
        font-size: 24px;
        color: var(--primary-dark);
      }
    }
  }

  &__label {
    position: absolute;
    top: 2px;
    left: 12px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: var(--primary-dark);
  }

  &.big {
    .lib-select__label {
      font-size: 18px;
      line-height: 22px;
      left: 18px;
      top: 11px;
    }
  }

  &.ant-select-disabled {
    .lib-select__label {
      color: var(--disabled-text-color);
    }
  }
}
