@mixin checkbox-inner {
  --_border-radius: 5px;
  --_checkmark-color: #fff;
  --_unchecked-background: var(--disabled-bg-color);

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: var(--_unchecked-background);
    }
  }

  .ant-checkbox-checked {
    &:not(.ant-checkbox-disabled) {
      .ant-checkbox-inner {
        background-color: var(--_checkbox-color);
        border-color: var(--primary-dark);

        &:after {
          border-color: var(--_checkmark-color);
        }
      }

      &:hover,
      &:focus {
        .ant-checkbox-inner {
          border-color: var(--primary-dark);
        }
      }

      // border: 1px solid var(--primary-dark);
    }

    &::after {
      border-radius: var(--_border-radius);
    }
  }

  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--primary-dark);
  }

  .ant-checkbox-checked::after {
    border-color: var(--primary-dark);
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--_checkbox-color);
  }
}

@mixin checkbox-colors-with-severity {
  --_checkbox-color: var(--primary-dark);

  &[severity='critical'] {
    --_checkbox-color: var(--primary-red-6);
  }

  &[severity='major'] {
    // --_checkbox-color: var(--calendula-gold-6);
    --_checkbox-color: #fff000;
    --_checkmark-color: var(--primary-dark);
    --_unchecked-background: #fff000;
  }

  &[severity='info'] {
    // --_checkbox-color: var(--primary-green-6);
    --_checkbox-color: #007e50;
    --_unchecked-background: #007e50;
  }
}

td.checkbox {
  --_td-checkbox-color: var(--primary-dark);

  &[severity='critical'] {
    --_td-checkbox-color: var(--primary-red-6);
  }

  &[severity='major'] {
    --_td-checkbox-color: var(--calendula-gold-6);
  }

  &[severity='info'] {
    --_td-checkbox-color: var(--primary-green-6);
  }

  .ant-checkbox-wrapper {
    --_checkbox-color: var(--_td-checkbox-color);
    @include checkbox-inner;
  }
}

.ant-checkbox-wrapper {
  @include checkbox-colors-with-severity;
  @include checkbox-inner;

  &:hover {
    .ant-checkbox-inner {
      border-color: var(--_checkbox-color);
    }
  }

  .ant-checkbox-inner {
    transition: none;
  }

  &.big {
    .ant-checkbox-inner {
      width: 32px;
      height: 32px;
      border-radius: var(--_border-radius);

      &::after {
        width: 10.714286px;
        height: 18.142857px;
      }
    }

    &.ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
      width: 32px;
      height: 32px;
      border-radius: var(--_border-radius);
    }
  }
}
