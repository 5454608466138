.overdue-task-list-actions-dropdown {
  top: -26px;
  left: -35px;

  ul {
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 2px 14px #00000026;
    border: 1px solid #dbdcdd;
    border-radius: 4px;
    padding: 0;

    li {
      width: 171px;
      height: 39px;
      display: flex;
      align-items: center;
      padding-left: 8px;
      font: normal normal normal 16px/24px Lato;
      color: #252a36;
      cursor: pointer;
      border-bottom: 1px solid #dbdcdd;

      &:last-child {
        border-bottom: 1px solid transparent;
      }

      &:hover {
        background: #f1f2f5 0 0 no-repeat padding-box;
      }
    }
  }
}
